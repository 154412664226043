.app-header .hm-avatar {
  float: right;
  border: 0;
  background: none;
  box-shadow: none;
  min-height: auto !important;
}

.app-header .ui.image {
  width: 32px;
  display: flex;
  align-items: center;
}

.app-header .ui.dropdown {
}

.app-header .ui.dropdown .icon {
  margin: 0 !important;
  color: #EAEAEA;
}

.hm-avatar .menu {
  margin: 4px 0 0 -56px !important;
}

.hm-avatar .menu .icon {
  color: black !important;
}

.hm-avatar .menu .divider {
  margin: 0 !important;
}
