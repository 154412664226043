@import url(https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@1,700&display=swap);
.menu {
  display: flex;
}

.menu-item {
  /*-webkit-box-shadow: 0px 5px 10px -6px rgb(43, 42, 42);*/
  /*-moz-box-shadow: 0px 5px 10px -6px rgb(43, 42, 42);*/
  /*box-shadow: 0px 5px 10px -6px rgb(43, 42, 42);*/
  /*border-radius: 20px;*/
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item.active {
  box-shadow: none;
  background: white;
}

.menu-item + .menu-item{
  margin-left: 12px;
}

.menu-item .icon {
  color: #EAEAEA !important;
  margin: 0;
}

.menu-item .icon.active {
  color: black !important;
}

.selector {
 width: 32px;
 height: 1em;
 background: white;
 position: absolute;
 left: 0px;
 bottom: -10px;
}

.app-header .hm-avatar {
  float: right;
  border: 0;
  background: none;
  box-shadow: none;
  min-height: auto !important;
}

.app-header .ui.image {
  width: 32px;
  display: flex;
  align-items: center;
}

.app-header .ui.dropdown {
}

.app-header .ui.dropdown .icon {
  margin: 0 !important;
  color: #EAEAEA;
}

.hm-avatar .menu {
  margin: 4px 0 0 -56px !important;
}

.hm-avatar .menu .icon {
  color: black !important;
}

.hm-avatar .menu .divider {
  margin: 0 !important;
}

.footer-left {
  padding: 0 1em;
  font: 16px Lobster Two;
  color: #5A5A4A;
}

.footer-center {
}

.footer-right {
  padding: 0 1em;
  font: 16px Lobster Two;
  color: #5A5A5A;
}



.product {
  display: flex;
  align-items: unset;
  justify-content: space-between;
}

.product .checkbox {
  border-left: 2px solid #ddd;
  display: flex !important;
  align-items: center;
  padding-left: 0.5em;
}

.product .checkbox.overdue {
  border-left: 2px solid rgb(249, 117, 117);
}

.product .checkbox.on-time {
  border-left: 2px solid rgb(117, 225, 249);
}

.product .checkbox.early {
  border-left: 2px solid rgb(137, 230, 161);
}

.product .meta {
  font-size: 1em;
  color: rgba(0,0,0,.4);
}

.product-draft-list {
  /*border-right: 1px solid #5565bd47;*/
  overflow-y: scroll;
  height: calc(100% - 80px); /* 80px is the height of product input + list header */
  margin: 0;
  /*padding: 1em 0;*/
}

.product-draft-list::-webkit-scrollbar {
  width: 4px;
}

.product-draft-list::-webkit-scrollbar-track {
    background: white;
}

.product-draft-list::-webkit-scrollbar-thumb {
    background: #ddd;
    visibility:hidden;
}

.product-draft-list:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}

.product-suggestion-list {
  #border-left: 1px solid #F7F7F7;
  overflow-y: scroll;
  height: calc(100% - 30px);
  margin: 52px 0 0 0 !important;
  padding: 0;
}

.product-suggestion-list::-webkit-scrollbar {
  width: 4px;
}

.product-suggestion-list::-webkit-scrollbar-track {
    background: white;
}

.product-suggestion-list::-webkit-scrollbar-thumb {
    background: #ddd;
    visibility:hidden;
}

.product-suggestion-list:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}


.product-suggestion {
  display: flex;
  align-items: unset;
  justify-content: space-between;
}



.app-loader {
  display: flex;
  align-items: center;
  height: 100%;
}

.section-content {
	height: 100%;
}

.section-content > .main-content {
	height: 100%;
}

.section-content > .side-content {
	height: 100%;
}

.product-draft-list-header {
  display: flex !important;
  justify-content: space-between;
  height: auto !important;
  align-items: center;
  padding: 0 !important;
	margin-bottom: 24px;
}

.product-draft-list-header .header {
	margin: 0;
}

.product-draft-list-header .buy-button {
  background: rgb(243 99 99);
  margin: 0;
  /*margin-right: 4px;*/
  /*width: 68px;*/
}

.product-draft-list-header .buy-button:hover {
  background: rgb(243 99 99);
}

.empty-product-draft-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 80px;
	align-items: center;
}

.empty-product-draft-list .ui.header {
}

.empty-product-draft-list .ui.button {
  margin-top: 10px;
  /*background: rgb(90, 100, 151);*/
  background: rgb(243 99 99);
	color: white;
}

.ui.button.confirm-button {
  background: rgb(243 99 99);
	color: white;
}

.label {
	#width: 3em !important;
}

.label > img {
	border-radius: 0 !important;
}

.ui.grid {
  margin: 0;
  align-items: center;
}

/* app */
.app-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.app-header-container {
  border-bottom: 1px solid #E4E4E4;
  margin: 0;
  background: #5b6498;
}

.app-header-container.on-lists {
  background: #5565BD;
}

.app-header-container.on-drafts {
  background: #bd5555;
}

.app-header-wrapper {
  padding: 0 !important;
}

.app-header {
  padding: 0.3rem 1em !important;
}

.app-header > div {
  padding: 0 !important;
}

.app-content-container {
  margin: 0 !important;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.app-content-container::-webkit-scrollbar {
    display: none;
}

.app-content-wrapper {
  /* padding: 0 !important; */
  height: 100%;
}

.app-footer-container {
  border-top: 1px solid #E4E4E4;
  margin-top: auto !important;
  height: 60px;
}

.app-footer-wrapper {
}

.app-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* content */
.app-content {
  /*padding: 1em 0;*/
  height: 100%;
}

.app-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5em 1em;
}

.app-content-header > * {
  margin: 0 !important;
}


.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  font-family: Lobster Two;
  font-style: italic;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #5A5A5A;
  margin-top: -80px;
}

.logo > span {
  width: 100%;
}

.actions {
  margin-top: 20px;
}

.login-button {
  background: #5b6498 !important;
  color: #EAEAEA !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}


