.login-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  font-family: Lobster Two;
  font-style: italic;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #5A5A5A;
  margin-top: -80px;
}

.logo > span {
  width: 100%;
}

.actions {
  margin-top: 20px;
}

.login-button {
  background: #5b6498 !important;
  color: #EAEAEA !important;
}
