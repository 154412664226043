.product-draft-list {
  /*border-right: 1px solid #5565bd47;*/
  overflow-y: scroll;
  height: calc(100% - 80px); /* 80px is the height of product input + list header */
  margin: 0;
  /*padding: 1em 0;*/
}

.product-draft-list::-webkit-scrollbar {
  width: 4px;
}

.product-draft-list::-webkit-scrollbar-track {
    background: white;
}

.product-draft-list::-webkit-scrollbar-thumb {
    background: #ddd;
    visibility:hidden;
}

.product-draft-list:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}

.product-suggestion-list {
  #border-left: 1px solid #F7F7F7;
  overflow-y: scroll;
  height: calc(100% - 30px);
  margin: 52px 0 0 0 !important;
  padding: 0;
}

.product-suggestion-list::-webkit-scrollbar {
  width: 4px;
}

.product-suggestion-list::-webkit-scrollbar-track {
    background: white;
}

.product-suggestion-list::-webkit-scrollbar-thumb {
    background: #ddd;
    visibility:hidden;
}

.product-suggestion-list:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}

