@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@1,700&display=swap');

.ui.grid {
  margin: 0;
  align-items: center;
}

/* app */
.app-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.app-header-container {
  border-bottom: 1px solid #E4E4E4;
  margin: 0;
  background: #5b6498;
}

.app-header-container.on-lists {
  background: #5565BD;
}

.app-header-container.on-drafts {
  background: #bd5555;
}

.app-header-wrapper {
  padding: 0 !important;
}

.app-header {
  padding: 0.3rem 1em !important;
}

.app-header > div {
  padding: 0 !important;
}

.app-content-container {
  margin: 0 !important;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.app-content-container::-webkit-scrollbar {
    display: none;
}

.app-content-wrapper {
  /* padding: 0 !important; */
  height: 100%;
}

.app-footer-container {
  border-top: 1px solid #E4E4E4;
  margin-top: auto !important;
  height: 60px;
}

.app-footer-wrapper {
}

.app-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* content */
.app-content {
  /*padding: 1em 0;*/
  height: 100%;
}

.app-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.5em 1em;
}

.app-content-header > * {
  margin: 0 !important;
}

