.footer-left {
  padding: 0 1em;
  font: 16px Lobster Two;
  color: #5A5A4A;
}

.footer-center {
}

.footer-right {
  padding: 0 1em;
  font: 16px Lobster Two;
  color: #5A5A5A;
}
