.menu {
  display: flex;
}

.menu-item {
  /*-webkit-box-shadow: 0px 5px 10px -6px rgb(43, 42, 42);*/
  /*-moz-box-shadow: 0px 5px 10px -6px rgb(43, 42, 42);*/
  /*box-shadow: 0px 5px 10px -6px rgb(43, 42, 42);*/
  /*border-radius: 20px;*/
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item.active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: white;
}

.menu-item + .menu-item{
  margin-left: 12px;
}

.menu-item .icon {
  color: #EAEAEA !important;
  margin: 0;
}

.menu-item .icon.active {
  color: black !important;
}

.selector {
 width: 32px;
 height: 1em;
 background: white;
 position: absolute;
 left: 0px;
 bottom: -10px;
}
